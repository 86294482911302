import { createApp } from "vue";
import "./assets/styles/reset.scss";
import "./assets/styles/global.scss";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import "./utils/rem";

const app = createApp(App);

app.use(store);
app.use(router);

//挂载app
app.mount("#app");
