import { createRouter, createWebHistory } from "vue-router";
// import routes from "./routes"

const isMobile = document.body.clientWidth < 992;
const routes = [
  {
    path: "/",
    name: "Home",
    meta: {
      title: "杭州凡佶互连科技有限公司",
    },
    component: () =>
      isMobile
        ? import(/* webpackChunkName: "home" */ "../views/mobile/mHome.vue")
        : import(/* webpackChunkName: "home" */ "../views/home.vue"),
  },
  {
    path: "/about",
    name: "About",
    component: () =>
      isMobile
        ? import(/* webpackChunkName: "home" */ "../views/mobile/mAbout.vue")
        : import(/* webpackChunkName: "home" */ "../views/about.vue"),
    meta: {
      title: "关于凡佶",
    },
  },
  {
    path: "/news",
    name: "News",
    component: () =>
      isMobile
        ? import(
            /* webpackChunkName: "group-user" */ "../views/mobile/mNews.vue"
          )
        : import(/* webpackChunkName: "group-user" */ "../views/news.vue"),

    meta: {
      title: "新闻资讯",
    },
  },
  {
    path: "/join",
    name: "Join",
    component: () =>
      isMobile
        ? import(
            /* webpackChunkName: "group-user" */ "../views/mobile/mJoin.vue"
          )
        : import(/* webpackChunkName: "group-user" */ "../views/join.vue"),

    meta: {
      title: "加入我们",
    },
  },
  {
    path: "/business",
    name: "Business",
    component: () =>
      isMobile
        ? import(
            /* webpackChunkName: "group-user" */ "../views/mobile/mBusiness.vue"
          )
        : import(/* webpackChunkName: "group-user" */ "../views/business.vue"),
    meta: {
      title: "业务方向",
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  // 页面刷新回到顶部
  scrollBehavior() {
    return {
      y: 0,
    };
  },
});
router.beforeEach((to, from, next) => {
  window.document.title = to.meta.title;
  window, scrollTo(0, 0);
  next();
});

export default router;
