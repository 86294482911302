export default {
  state: {
    //移动端布局
    ismobile: false,
  },
  mutations: {
    SET_ismobile(state, key) {
      state.ismobile = key;
    },
  },
};
